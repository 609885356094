import { ComponentType, ReactNode } from 'react'
// import { createInstantSearch } from 'react-instantsearch-dom/server'
import { connectSearchBox, SearchBoxProvided, SearchState } from 'react-instantsearch-core'
import { Configure, InstantSearch, RangeInput, RefinementList } from 'react-instantsearch-dom'
import { AlgoliaIndex } from 'search/enums/search'
import styled from 'styled-components'
import algoliaClient from './AlgoliaSearchClient'

const Hidden = styled.div`
  display: none;
`
interface IAlgoliaInstantSearchProps {
  children?: ReactNode
  filters?: string
  hitsPerPage?: number
  indexName: string
  onSearchStateChange?: ((...args: any[]) => any) | undefined
  resultsState?: unknown
  searchState?: SearchState
  searchClient: any
  widgetsCollector?: ((...args: any[]) => any) | undefined
  ruleContexts?: string[]
}

// const { InstantSearch, findResultsState } = createInstantSearch()

const CustomSearchBox: ComponentType<Partial<SearchBoxProvided>> = () => <div />
const HiddenSearchBox = connectSearchBox(CustomSearchBox)

/**
 * https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#widget-param-searchstate
 *
 * Component to set up an aloglia search.  With this component as the "wrapper", algolia react
 * components and hocs are then available to display and interact with this search. The Component
 * provides an instant search "wrapper" component as well as a findResultsState method to
 * use to server side render search
 *
 * Usage:
 * const resultsState = await findResultsState(AlgoliaInstantSearch, {
 *    indexName: AlgoliaIndex.Trending,
 * })
 * <AlgoliaInstantSearch resultsState={resultsState} index={AlgoliaIndex.Trending}>
 *   <ProductHits />
 * </AlgoliaInstantSearch>
 */

const PageLevelInstantSearch = ({
  children,
  filters = '',
  hitsPerPage = 8,
  indexName = AlgoliaIndex.Relevance,
  onSearchStateChange = () => null,
  resultsState,
  searchState = {},
  widgetsCollector = () => null,
  ruleContexts,
}: IAlgoliaInstantSearchProps) => {
  return (
    <InstantSearch
      indexName={indexName}
      searchClient={algoliaClient}
      searchState={searchState}
      resultsState={resultsState}
      onSearchStateChange={onSearchStateChange}
      widgetsCollector={widgetsCollector}
    >
      <Hidden>
        <RefinementList attribute="brand_name" />
        <RefinementList attribute="silhouette" />
        <RefinementList attribute="presentation_size" />
        <RefinementList attribute="size_us_men" />
        <RefinementList attribute="size_us_women" />
        <RefinementList attribute="size_us_youth" />
        <RefinementList attribute="shoe_condition" />
        <RefinementList attribute="color" />
        <RefinementList attribute="single_gender" />
        <RefinementList attribute="category" />
        <RefinementList attribute="product_category" />
        <RefinementList attribute="shoe_condition" />
        <RefinementList attribute="designer" />
        <RefinementList attribute="collection_slugs" />
        <RefinementList attribute="is_under_retail" />
        <RangeInput attribute="lowest_price_cents_usd" />
        <RangeInput attribute="release_year" />
      </Hidden>
      <Configure hitsPerPage={hitsPerPage} ruleContexts={ruleContexts} filters={filters} distinct />
      {/*
        IMPORTANT: Another issue with algolia react components,  we need a searchbox component
        for the InstantSearch component to accept the query property when server side rendered
      */}
      <HiddenSearchBox />
      {children}
    </InstantSearch>
  )
}

export { PageLevelInstantSearch }
export default PageLevelInstantSearch
